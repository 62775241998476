import React from 'react';

import { colors, styled } from 'core/styles';
import { Rating, ratingToLetter } from 'domain/types';
import { MaybeEither, toEither } from 'utils/either';
import { ratingColors } from 'domain/utils/constants';

export type RatingIconProps = {
  rating: MaybeEither<Rating>;
};

export function RatingIcon({ rating }: RatingIconProps) {
  return toEither(rating).fold(
    r => <IconContainer rating={r}>{ratingToLetter(r)}</IconContainer>,
    () => <IconContainer rating="N/A">-</IconContainer>,
  );
}

const IconContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'rating',
})<{
  rating: Rating | 'N/A';
}>(({ rating }) => ({
  position: 'absolute',
  top: '-8px',
  right: '-8px',
  width: '16px',
  height: '16px',
  borderRadius: '100px',
  background: ratingColors[rating],
  color: colors.veryDark,
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'center',
}));
