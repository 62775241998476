import React from 'react';

import { styled } from 'core/styles';

export function AnimatedDots() {
  return (
    <Root>
      <span>&nbsp;.</span>
      <span>&nbsp;.</span>
      <span>&nbsp;.</span>
    </Root>
  );
}

const Root = styled('span')(() => ({
  '& span': {
    animation: '1.8s ease-in-out .5s infinite both pulse',
    '&:nth-of-type(2)': {
      animationDelay: '.8s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '1s',
    },
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));
