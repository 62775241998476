/* eslint-disable no-constructor-return */
import { map, share, switchMap } from 'rxjs/operators';
import * as R from 'ramda';
import { Observable } from 'rxjs';

import {
  AssetRating,
  MarketRating,
  NetworkSlug,
  PositionRating,
  ProductSlug,
  ProtocolRating,
  VersionSlug,
} from 'domain/types';
import { memoize } from 'utils/decorators';
import { Either, left, right } from 'utils/either';
import { ERROR_MESSAGES } from 'domain/utils/constants';
import { resetOnZeroRefs } from 'utils/rxjs';
import { CIMap } from 'utils/js';

import { apiErrorInterceptor } from './apiErrorInterceptor';
import {
  convertAssetRatings,
  convertMarketRating,
  convertMarketRatings,
  convertAccountPositions,
  convertProtocolRatings,
} from './converters';
import { globalApi } from './globalApi';
import {
  loadAccountPositions,
  loadAssetsRatings,
  loadMarketsRatings,
  loadProtocolsRatings,
} from './apostro-rest/public';

/* eslint-disable class-methods-use-this */
class RatingsApi {
  constructor() {
    return apiErrorInterceptor.getProxiedObj(this);
  }

  @memoize((...args: string[]) => R.toString(args))
  public getProtocolRatings$(protocolId: number): Observable<ProtocolRating[]> {
    return this.getProtocolsRatings$().pipe(
      map((ratings): ProtocolRating[] => ratings.get(protocolId) || []),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getProtocolsRatings$(): Observable<Map<number, ProtocolRating[]>> {
    return globalApi.getDataTimestamp$('latest').pipe(
      switchMap(timestamp => loadProtocolsRatings({ timestamp })),
      map(convertProtocolRatings),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getWorstMarketRating$({
    productSlug,
    versionSlug,
    network,
    marketId,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    marketId: string;
  }): Observable<Either<MarketRating>> {
    return this.getMarketRatings$({ network, productSlug, versionSlug, marketId }).pipe(
      map(ratings => {
        const rating: MarketRating | undefined = [...ratings].sort(
          (x, y) => y.rating - x.rating,
        )[0];
        return rating ? right(rating) : left(ERROR_MESSAGES.UNKNOWN_RATING);
      }),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getMarketRatings$({
    productSlug,
    versionSlug,
    network,
    marketId,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    marketId: string;
  }): Observable<MarketRating[]> {
    return globalApi.getDataTimestamp$('latest').pipe(
      switchMap(timestamp =>
        loadMarketsRatings({
          timestamp,
          network_slug: network,
          product_slug: productSlug,
          version_slug: versionSlug,
          market_vid: marketId,
        }),
      ),
      map(ratings => ratings.map(convertMarketRating)),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getMarketsRatings$({
    productSlug,
    versionSlug,
    network,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
  }): Observable<Map<string, MarketRating[]>> {
    return globalApi.getDataTimestamp$('latest').pipe(
      switchMap(timestamp =>
        loadMarketsRatings({
          timestamp,
          network_slug: network,
          product_slug: productSlug,
          version_slug: versionSlug,
        }),
      ),
      map(convertMarketRatings),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getAssetsRatings$({
    productSlug,
    versionSlug,
    network,
    marketId,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    marketId?: string;
  }): Observable<CIMap<string, AssetRating>> {
    return globalApi.getDataTimestamp$('latest').pipe(
      switchMap(timestamp =>
        loadAssetsRatings({
          timestamp,
          network_slug: network,
          product_slug: productSlug,
          version_slug: versionSlug,
          market_vid: marketId,
        }),
      ),
      map(ratings => convertAssetRatings(ratings)),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getAccountPositionsRatings$(accountVid: string): Observable<PositionRating[]> {
    return globalApi.getDataTimestamp$('latest').pipe(
      switchMap(timestamp => loadAccountPositions({ timestamp, account_vid: accountVid })),
      map(ratings => convertAccountPositions(ratings, accountVid)),
      share(resetOnZeroRefs()),
    );
  }
}

export const ratingsApi = new RatingsApi();
