'use client';

import React from 'react';
import { CircularProgress as CircularProgressComponent } from '@mui/material';

import { styled } from 'core/styles';

import { AnimatedDots } from '../AnimatedDots';

export function GlobalPreloader() {
  return (
    <Root>
      <CircularProgress size={60} />
      <Text>
        Loading
        <AnimatedDots />
      </Text>
    </Root>
  );
}

const Root = styled('div')(() => ({
  display: 'flex',
  minHeight: 150,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'column',
  flexGrow: 1,
}));

const CircularProgress = styled(CircularProgressComponent)(() => ({
  fontSize: 150,
  color: 'currentcolor',
}));

const Text = styled('div')(() => ({
  fontSize: 15,
  marginTop: 10,
}));
