import { LiquidityAmount, PercentAmount, Token } from '@akropolis-web/primitives';

import { components } from 'api/generated/openapi/protocols';
import { Either } from 'utils/either';

import { NetworkSlug, ProductSlug, VersionSlug } from './slug';

type schemas = components['schemas'];

export type Rating = 0 | Exclude<schemas['RatingValue'], ''>;
export type RatingType = schemas['RatingType'] | 'UNKNOWN';

export type PositionID = number;

const ratingLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'] as const;
export function ratingToLetter(rating: Rating) {
  return ratingLetter[rating];
}

// ----- PROTOCOL RATINGS ----- //

export type ProtocolRating =
  | ProtocolRatingBase<'UNKNOWN'>
  | ProtocolTotalRating
  | ProtocolWeightedAvgRating;

export type ProtocolTotalRating = ProtocolRatingBase<'TOTAL'>;
export type ProtocolWeightedAvgRating = ProtocolRatingBase<'WEIGHTED_AVERAGE'>;

type ProtocolRatingBase<T extends RatingType> = {
  rating: Rating;
  ratingType: T;
  protocolId: number;
};

export function isProtocolTotalRating(rating: ProtocolRating): rating is ProtocolTotalRating {
  return rating.ratingType === 'TOTAL';
}

export function isProtocolWeightedAvgRating(
  rating: ProtocolRating,
): rating is ProtocolWeightedAvgRating {
  return rating.ratingType === 'WEIGHTED_AVERAGE';
}

// ----- MARKET RATINGS ----- //

export type MarketRating =
  | MarketTotalRating
  | MarketBadDebtRating
  | MarketManipulationComplexityRating
  | MarketRatingBase<'UNKNOWN', null>;

export type MarketTotalRating = MarketRatingBase<'TOTAL', null>;

export type MarketBadDebtRating = MarketRatingBase<
  'BAD_DEBT',
  {
    maxDebt: LiquidityAmount;
    debtShare: PercentAmount;
    badDebtsSum: LiquidityAmount;
    marketTvl: LiquidityAmount;
  }
>;

export type MarketManipulationComplexityRating = MarketRatingBase<
  'MANIPULATION_COMPLEXITY',
  {
    dumpCost: LiquidityAmount | null;
    dumpRating: Rating;
    dumpRiskTvl: LiquidityAmount | null;
    isStable: boolean;
    pumpCost: LiquidityAmount | null;
    pumpRating: Rating;
    pumpRiskTvl: LiquidityAmount | null;
  }
>;

type MarketRatingBase<T extends RatingType, P> = {
  rating: Rating;
  ratingType: T;
  protocolId: number;
  marketVid: string;
  payload: P;
};

export function isMarketTotalRating(rating: MarketRating): rating is MarketTotalRating {
  return rating.ratingType === 'TOTAL';
}

export function isMarketBadDebtRating(rating: MarketRating): rating is MarketBadDebtRating {
  return rating.ratingType === 'BAD_DEBT';
}

export function isMarketManipulationComplexityRating(
  rating: MarketRating,
): rating is MarketManipulationComplexityRating {
  return rating.ratingType === 'MANIPULATION_COMPLEXITY';
}

// ----- ASSET RATINGS ----- //

export type AssetRating =
  | AssetRatingBase<'UNKNOWN', null>
  | AssetTotalRating
  | AssetManipulationComplexityRating;

export type AssetTotalRating = AssetRatingBase<'TOTAL', null>;

export type AssetManipulationComplexityRating = AssetRatingBase<
  'MANIPULATION_COMPLEXITY',
  {
    dumpCost: LiquidityAmount | null;
    dumpRating: Rating;
    dumpRiskTvl: LiquidityAmount | null;
    isStable: boolean;
    pumpCost: LiquidityAmount | null;
    pumpRating: Rating;
    pumpRiskTvl: LiquidityAmount | null;
  }
>;

type AssetRatingBase<T extends RatingType, P> = {
  rating: Rating;
  ratingType: T;
  protocolId: number;
  marketVid: string;
  poolVid: string;
  tokenAddress: string;
  payload: P;
};

export function isAssetTotalRating(rating: AssetRating): rating is AssetTotalRating {
  return rating.ratingType === 'TOTAL';
}

export function isAssetManipulationComplexityRating(
  rating: AssetRating,
): rating is AssetManipulationComplexityRating {
  return rating.ratingType === 'MANIPULATION_COMPLEXITY';
}

// ----- POSITION RATINGS ----- //

export type PositionRating = {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  network: NetworkSlug;
  marketVid: string;
  accountVid: string;
  positionId: number;
  rating: Rating;
  usdValueAtRisk: number;
  originalRating: number; // not rounded
};

// ----- RATING INFO ----- //

export type ProtocolAvgRatingInfo = {
  rating: ProtocolRating;
  transcription: {
    productName: string;
    hasBadDebtRating: boolean;
    hasManipulationComplexityRating: boolean;
    worstBadDebtMarkets: {
      rating: Rating;
      marketNames: string[];
    } | null;
    worstManipulationComplexityMarkets: {
      rating: Rating;
      marketNames: string[];
    } | null;
  };
};

export type AccountAvgRatingInfo = {
  accountRating: Rating;
  mostRiskyPosition: {
    estimatedAccountRating: number; // without the most risky position
    positionRating: Rating;
    isMultiMarketProtocol: boolean;
    marketVid: string;
    marketName: string;
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    productName: string;
    network: NetworkSlug;
    share: number;
  } | null;
};

export type MarketRatingInfo = {
  manipulationComplexityInfo: MarketManipulationComplexityRatingInfo | null;
  badDebtInfo: MarketBadDebtRatingInfo | null;
  marketRating: Either<Rating>;
};

export type MarketBadDebtRatingInfo = {
  rating: MarketBadDebtRating;
  transcription: {
    marketName: string;
  };
};

export type MarketManipulationComplexityRatingInfo = {
  rating: MarketManipulationComplexityRating;
  valueAtRisk: LiquidityAmount | null;
  impactCost: LiquidityAmount | null;
  transcription: {
    marketName: string;
    badAssets: Token[];
  };
};
