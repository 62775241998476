'use client';

// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { styled } from 'core/styles';

type OmittedLinkProps = Omit<LinkProps, 'passHref' | 'legacyBehavior' | 'href'>;

export type NextLinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof OmittedLinkProps
> &
  OmittedLinkProps & {
    href: string;
    children?: React.ReactNode;
  };

export const NextLink = React.forwardRef<HTMLAnchorElement, NextLinkProps>((props, ref) => {
  const {
    href,
    replace,
    as,
    scroll,
    shallow,
    prefetch,
    children,
    locale,
    onMouseEnter,
    onTouchStart,
    onClick,
    ...rest
  } = props;

  return (
    <Link
      href={href}
      prefetch={prefetch}
      replace={replace}
      as={as}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      onClick={onClick}
      passHref
      legacyBehavior
    >
      <LinkA ref={ref} {...rest}>
        {children}
      </LinkA>
    </Link>
  );
});

const LinkA = styled('a')(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));
