import { ProductSlug } from 'domain/types/slug';

import inverseLogo from './images/inverse.png';
import compoundLogo from './images/comp.svg';
import aaveLogo from './images/aave.svg';
import creamLogo from './images/cream.svg';
import dForceLogo from './images/dforce.svg';
import ironBankLogo from './images/iron-bank.svg';
import makerLogo from './images/maker.svg';
import siloLogo from './images/silo.svg';
import uwuLend from './images/uwu-lend.png';
import radiantCapital from './images/radiant-v1.webp';
import geistFinance from './images/geist-finance.svg';
import sonneFinance from './images/sonne-finance.png';
import benqiLending from './images/benqi-lending.svg';
import screamLogo from './images/scream.png';
import apeswapLending from './images/apeswap-lending.svg';
import fluxFinance from './images/flux-finance.png';
import fraxlendLogo from './images/fraxlend.svg';
import liqeeLogo from './images/liqee.svg';
import lodestarFinance from './images/lodestar-finance.png';
import onyxProtocol from './images/onyx-protocol.svg';
import rariCapital from './images/rari-capital.png';
import strikeLogo from './images/strike.svg';
import tenderFinance from './images/tender-finance.png';
import traderJoeLend from './images/trader-joe-lend.svg';
import wepiggyLogo from './images/wepiggy.svg';
import radiantLogo from './images/radiant.svg';
import venusLogo from './images/venus.png';
import sparkLogo from './images/spark.svg';
import unitusLogo from './images/unitus.png';
import moonwellLogo from './images/moonwell.webp';
import zeroLend from './images/zero-lend.webp';
import aurigamiLogo from './images/aurigami.svg';
import bastionLogo from './images/bastion.png';
import morphoBlueLogo from './images/morpho-blue.svg';

const protocolsLogos: Record<string, StaticImageData> = {
  'inverse-finance': inverseLogo,
  compound: compoundLogo,
  aave: aaveLogo,
  'cream-finance': creamLogo,
  dforce: dForceLogo,
  'iron-bank': ironBankLogo,
  maker: makerLogo,
  silo: siloLogo,
  'uwu-lend': uwuLend,
  'radiant-capital': radiantCapital,
  'geist-finance': geistFinance,
  'sonne-finance': sonneFinance,
  'benqi-lend': benqiLending,
  scream: screamLogo,
  'apeswap-lending': apeswapLending,
  'flux-finance': fluxFinance,
  fraxlend: fraxlendLogo,
  liqee: liqeeLogo,
  lodestar: lodestarFinance,
  'onyx-protocol': onyxProtocol,
  'rari-fuse': rariCapital,
  strike: strikeLogo,
  'tender-finance': tenderFinance,
  'trader-joe-lend': traderJoeLend,
  wepiggy: wepiggyLogo,
  radiant: radiantLogo,
  venus: venusLogo,
  spark: sparkLogo,
  unitus: unitusLogo,
  moonwell: moonwellLogo,
  zerolend: zeroLend,
  aurigami: aurigamiLogo,
  bastion: bastionLogo,
  'morpho-blue': morphoBlueLogo,
};

export const getProtocolLogo = (productSlug: ProductSlug): StaticImageData | null => {
  const logo = protocolsLogos[productSlug];
  return logo || null;
};
