// @ts-check

module.exports = {
  protocolsHosts: [
    {
      host: 'analytics.silo.finance',
      slug: 'silo',
      versions: ['llama', 'default'],
    },
  ],
};
