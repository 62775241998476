import arbitrumColor from './images/arbitrum-color.svg';
import arbitrumExplorer from './images/arbitrum-explorer.svg';
import auroraColor from './images/aurora-color.svg';
import auroraExplorer from './images/aurora-explorer.svg';
import avalancheColor from './images/avalanche-color.svg';
import avalancheExplorer from './images/avalanche-explorer.svg';
import baseColor from './images/base-color.svg';
import bscColor from './images/bsc-color.svg';
import fantomColor from './images/fantom-color.svg';
import fantomExplorer from './images/fantom-explorer.svg';
import gnosisColor from './images/gnosis-color.svg';
import gnosisExplorer from './images/gnosis-explorer.svg';
import harmonyColor from './images/harmony-color.svg';
import harmonyExplorer from './images/harmony-explorer.svg';
import mainnetColor from './images/mainnet-color.svg';
import mainnetExplorer from './images/mainnet-explorer.svg';
import moonbeamColor from './images/moonbeam-color.svg';
import moonbeamExplorer from './images/moonbeam-explorer.svg';
import moonriverColor from './images/moonriver-color.svg';
import moonriverExplorer from './images/moonriver-explorer.svg';
import optimismColor from './images/optimism-color.svg';
import optimismExplorer from './images/optimism-explorer.svg';
import polygonColor from './images/polygon-color.svg';
import polygonExplorer from './images/polygon-explorer.svg';
import zcSyncEraColor from './images/zksync-era-color.svg';
import zcSyncEraExplorer from './images/zksync-era-explorer.svg';
import mantaColor from './images/manta-color.svg';
import mantaExplorer from './images/manta-explorer.svg';
import lineaColor from './images/linea-color.svg';
import lineaExplorer from './images/linea-explorer.svg';

export const networkColorIcons: Partial<Record<string, StaticImageData>> = {
  mainnet: mainnetColor,
  'arbitrum-one': arbitrumColor,
  polygon: polygonColor,
  avalanche: avalancheColor,
  optimism: optimismColor,
  fantom: fantomColor,
  bsc: bscColor,
  harmony: harmonyColor,
  moonbeam: moonbeamColor,
  moonriver: moonriverColor,
  gnosis: gnosisColor,
  base: baseColor,
  aurora: auroraColor,
  'zksync-era': zcSyncEraColor,
  manta: mantaColor,
  linea: lineaColor,
};

export const explorerIcons: Partial<Record<string, StaticImageData>> = {
  default: mainnetExplorer,
  mainnet: mainnetExplorer,
  'arbitrum-one': arbitrumExplorer,
  polygon: polygonExplorer,
  avalanche: avalancheExplorer,
  optimism: optimismExplorer,
  fantom: fantomExplorer,
  bsc: mainnetExplorer,
  harmony: harmonyExplorer,
  moonbeam: moonbeamExplorer,
  moonriver: moonriverExplorer,
  gnosis: gnosisExplorer,
  base: mainnetExplorer,
  aurora: auroraExplorer,
  'zksync-era': zcSyncEraExplorer,
  manta: mantaExplorer,
  linea: lineaExplorer,
};
