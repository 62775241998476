import { useParams as useNextParams } from 'next/navigation';
import * as R from 'ramda';

import { isValuableString } from 'utils/string';
import { NetworkSlug, ProductSlug, VersionSlug } from 'domain/types';

import { normalizeParam } from './normalizeParams';

export type NextParams = ReturnType<typeof useNextParams>;

const ROUTE_PARAMS = {
  productSlug: ['product_version', (param: string) => param.split('_')[0] || null],
  versionSlug: ['product_version', (param: string) => param.split('_')[1] || 'default'],
  marketId: ['marketId', (param: string) => normalizeParam(param)],
  accountId: ['accountId', (param: string) => normalizeParam(param)],
  network: ['network', R.identity],
  tokenSymbol: ['tokenSymbol', R.identity],
} as const;

type QueryParam = keyof typeof ROUTE_PARAMS;

export type RouteParamsTypes = {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  marketId: string;
  accountId: string;
  network: NetworkSlug;
  tokenSymbol: string;
};

export function useParams<P extends QueryParam>(
  strict: true,
  keys: P[],
): { [key in P]: RouteParamsTypes[key] };
export function useParams<P extends QueryParam>(
  strict: false,
  keys: P[],
): { [key in P]: RouteParamsTypes[key] | null };
export function useParams<P extends QueryParam>(
  strict: boolean,
  keys: P[],
): { [key in P]: RouteParamsTypes[key] | null } {
  const params = useNextParams();
  return parseParams(strict as true, params, keys);
}

export function parseParams<P extends QueryParam>(
  strict: true,
  params: NextParams,
  keys: P[],
): { [key in P]: RouteParamsTypes[key] };
export function parseParams<P extends QueryParam>(
  strict: false,
  params: NextParams,
  keys: P[],
): { [key in P]: RouteParamsTypes[key] | null };
export function parseParams<P extends QueryParam>(
  strict: boolean,
  params: NextParams,
  keys: P[],
): { [key in P]: RouteParamsTypes[key] | null } {
  return keys.reduce(
    (acc, cur) => {
      const param = params[ROUTE_PARAMS[cur][0]];
      const convert = ROUTE_PARAMS[cur][1];

      const isExists = isValuableString(param);

      if (strict && !isExists) {
        throw new Error(`"${ROUTE_PARAMS[cur]}" dynamic route parameter not found`);
      }

      return {
        ...acc,
        [cur]: isValuableString(param) ? convert(param) : null,
      };
    },
    {} as { [key in P]: RouteParamsTypes[key] | null },
  );
}
