import NextImage from 'next/image';
import React from 'react';

import nothingFoundImg from 'assets/images/nothing-found.svg';
import { colors, styled } from 'core/styles';

export function EmptySearchResults() {
  return (
    <Root>
      <NextImage src={nothingFoundImg.src} width={260} height={240} alt="" />
    </Root>
  );
}

const Root = styled('div')(() => ({
  fontSize: 15,
  background: colors.smokyWhite,
  padding: '10px 20px',
}));
