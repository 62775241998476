import build, { getParam } from 'build-route-tree';

import { PROD_APP_URL } from 'env';
import { RedirectLinkProps } from 'domain/types';

import { getSlugByProtocolsHost } from './getSlugByProtocolsHost';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useRouteUtils } from './useRouteUtilsContext';
import { RouteParamsTypes } from './useParams';

const rawTree = {
  address: {
    accountId: getParam({
      ratings: null,
      positions: null,
    }),
  },
  profile: null,
  protocols: {
    product_version: getParam({
      network: getParam({
        tokenSymbol: getParam({
          stats: null,
          creditLine: null,
          collateral: null,
        }),
        ratings: null,
        stats: null,
        assets: null,
        markets: {
          marketId: getParam({
            ratings: null,
            assets: null,
            positions: null,
          }),
        },
        positions: null,
        account: {
          accountId: getParam(null),
        },
        position: {
          accountId: getParam({
            marketId: getParam(null),
          }),
        },
      }),
    }),
  },
};

const routes = build(rawTree);

type WithRouteParams<T extends () => string> = Exclude<
  Parameters<T>[0],
  Record<any, any>
> extends never
  ? true
  : false;

type PathGetterParams<P> = OverrideParams<
  P extends Record<infer K, string>
    ? 'product_version' extends K
      ? Omit<P, 'product_version'> & { productSlug: string; versionSlug: string }
      : P
    : never
>;

type OverrideParams<T> = {
  [key in Extract<keyof T, keyof RouteParamsTypes>]: RouteParamsTypes[key];
};

type PathGetter<T extends () => string> = WithRouteParams<T> extends true
  ? (params: PathGetterParams<Parameters<T>[0]>) => RedirectLinkProps
  : () => RedirectLinkProps;

function makeRedirectPropsGetter<T extends { getRedirectPath: (...params: any[]) => string }>(
  routesNode: T,
  host: string | null,
): PathGetter<T['getRedirectPath']> {
  return (params?: Parameters<T['getRedirectPath']>[0]) => {
    const convertedParams =
      params &&
      ('productSlug' in params && 'versionSlug' in params
        ? {
            ...params,
            product_version: `${params.productSlug}_${params.versionSlug}`.replace('_default', ''),
          }
        : params);
    const path = routesNode.getRedirectPath(convertedParams);
    const customProductSlug = getSlugByProtocolsHost(host);

    if (!customProductSlug) {
      return {
        href: path,
      };
    }

    const rootPathWithVersion = routes.protocols.product_version.getRedirectPath({
      product_version: `${customProductSlug}_`,
    });

    if (path.startsWith(`${rootPathWithVersion}`)) {
      return {
        href: path.replace(rootPathWithVersion, '/'),
      };
    }

    const rootPathDefault = routes.protocols.product_version.getRedirectPath({
      product_version: `${customProductSlug}`,
    });

    if (path.startsWith(`${rootPathDefault}`)) {
      return {
        href: path.replace(rootPathDefault, ''),
      };
    }

    return {
      href: `${PROD_APP_URL}${path}`,
      target: '_blank',
    };
  };
}

/**
 * Please use this function only for server-side rendering.
 *
 * For client-side rendering, use {@link useRouteUtils}.
 */
export function makeRouteUtils(host: string | null) {
  const redirectTo = {
    protocols: makeRedirectPropsGetter(routes.protocols, host),
    protocol: makeRedirectPropsGetter(routes.protocols.product_version.network, host),
    protocolAssets: makeRedirectPropsGetter(routes.protocols.product_version.network.assets, host),
    market: makeRedirectPropsGetter(
      routes.protocols.product_version.network.markets.marketId,
      host,
    ),
    marketsRatings: makeRedirectPropsGetter(routes.protocols.product_version.network.markets, host),
    marketAssets: makeRedirectPropsGetter(
      routes.protocols.product_version.network.markets.marketId.assets,
      host,
    ),
    marketPositions: makeRedirectPropsGetter(
      routes.protocols.product_version.network.markets.marketId.positions,
      host,
    ),
    protocolRatings: makeRedirectPropsGetter(
      routes.protocols.product_version.network.ratings,
      host,
    ),
    protocolStats: makeRedirectPropsGetter(routes.protocols.product_version.network.stats, host),
    protocolPositions: makeRedirectPropsGetter(
      routes.protocols.product_version.network.positions,
      host,
    ),
    protocolAccount: makeRedirectPropsGetter(
      routes.protocols.product_version.network.account.accountId,
      host,
    ),
    globalAccount: makeRedirectPropsGetter(routes.address.accountId, host),
    globalAccountRatings: makeRedirectPropsGetter(routes.address.accountId.ratings, host),
    globalAccountPositions: makeRedirectPropsGetter(routes.address.accountId.positions, host),
    position: makeRedirectPropsGetter(
      routes.protocols.product_version.network.position.accountId.marketId,
      host,
    ),
    marketRatings: makeRedirectPropsGetter(
      routes.protocols.product_version.network.markets.marketId.ratings,
      host,
    ),
    protocolTokenStats: makeRedirectPropsGetter(
      routes.protocols.product_version.network.tokenSymbol.stats,
      host,
    ),
    protocolTokenCreditLine: makeRedirectPropsGetter(
      routes.protocols.product_version.network.tokenSymbol.creditLine,
      host,
    ),
    protocolTokenCollateral: makeRedirectPropsGetter(
      routes.protocols.product_version.network.tokenSymbol.collateral,
      host,
    ),
    globalProfile: makeRedirectPropsGetter(routes.profile, host),
  };

  const productSlug = getSlugByProtocolsHost(host);

  return productSlug
    ? {
        isCustomHost: true as const,
        productSlug,
        redirectTo,
      }
    : {
        isCustomHost: false as const,
        redirectTo,
      };
}
