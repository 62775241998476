'use client';

import { createContext, useContext } from 'react';

import { RouteUtils } from './types';

export const RouteUtilsContext = createContext<RouteUtils | null>(null);
export const RouteUtilsContextProvider = RouteUtilsContext.Provider;

export function useRouteUtils(): RouteUtils {
  const context = useContext(RouteUtilsContext);

  if (!context) {
    throw new Error('RouteUtilsContext is not found');
  }

  return context;
}
