import React from 'react';

import { networkColorIcons, explorerIcons } from 'assets/networks';

import { NextSvgIcon, NextSvgIconProps } from '../NextSvgIcon';

type Variant = 'network-color' | 'explorer';

export type NetworkIconProps = {
  slug: string;
  variant?: Variant;
  fallback?: JSX.Element | null;
} & Omit<NextSvgIconProps, 'image'>;

const icons: Record<Variant, Partial<Record<string, StaticImageData>>> = {
  'network-color': networkColorIcons,
  explorer: explorerIcons,
};

export function NetworkIcon(props: NetworkIconProps) {
  const { slug, fallback = null, variant = 'network-color', ...rest } = props;
  const networkIcon = icons[variant][slug];

  return networkIcon ? <NextSvgIcon image={networkIcon} {...rest} /> : fallback;
}
