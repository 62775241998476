import { Observable, combineLatest, map } from 'rxjs';

import { protocolsApi, ratingsApi } from 'api/modules';
import { LendingInfoBase, ProtocolRating } from 'domain/types';

export type HomePageData = {
  allProtocols: LendingInfoBase[];
  protocolsRatings: Map<number, ProtocolRating[]>;
};

export function getHomePageData(): Observable<HomePageData> {
  return combineLatest([protocolsApi.getLendings$(false), ratingsApi.getProtocolsRatings$()]).pipe(
    map(([protocols, protocolsRatings]) => ({ allProtocols: protocols, protocolsRatings })),
  );
}
