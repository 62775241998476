'use client';

import NextImage from 'next/image';
import React from 'react';
import { Link, Tooltip, lighten } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';

import { colors, styled } from 'core/styles';
import {
  NetworkSlug,
  ProductSlug,
  ProtocolWeightedAvgRating,
  Rating,
  VersionSlug,
  isProtocolWeightedAvgRating,
} from 'domain/types';
import { getProtocolLogo } from 'assets/protocolsLogos/protocolsLogos';
import { useRouteUtils } from 'routes';
import { PROPOSE_PROTOCOL_URL } from 'env';
import { EmptySearchResults, GlobalPreloader, Loading, NetworkIcon, NextLink } from 'components';
import { Either, left, right } from 'utils/either';
import { ERROR_MESSAGES, getNetworkTvl, getVersionTvl } from 'domain/utils/constants';
import { useServerData } from 'utils/react';
import { getHomePageData } from 'api/selectors/pages';

import { RatingIcon } from './components';

export function HomePage({ data }: { data: string }) {
  const pageDataRD = useServerData(() => getHomePageData(), [], data);

  return (
    <Loading data={pageDataRD} loader={<GlobalPreloader />}>
      {({ allProtocols, protocolsRatings }) =>
        !allProtocols.length ? (
          <EmptySearchResults />
        ) : (
          <Root>
            <PageTitle>Pick a protocol</PageTitle>
            <ProtocolsContainer>
              {Object.values(R.groupBy(a => a.productSlug + a.versionSlug, allProtocols))
                .sort(
                  (a, b) =>
                    getVersionTvl(b[0].productSlug, b[0].versionSlug) -
                    getVersionTvl(a[0].productSlug, a[0].versionSlug),
                )
                .map(protocolsGroup =>
                  protocolsGroup.sort(
                    (a, b) =>
                      getNetworkTvl(b.productSlug, b.versionSlug, b.network) -
                      getNetworkTvl(a.productSlug, a.versionSlug, a.network),
                  ),
                )
                .map(protocolsGroup => {
                  const { productSlug, versionSlug } = protocolsGroup[0];
                  const networks = protocolsGroup.map(protocol => protocol.network);
                  const networkNames = protocolsGroup.map(protocol => protocol.networkName);
                  const logoSrc = getProtocolLogo(productSlug)?.src;
                  const ratings = protocolsGroup.map(protocol => {
                    const rating: ProtocolWeightedAvgRating | undefined = (protocolsRatings
                      .get(protocol.id)
                      ?.filter(isProtocolWeightedAvgRating) || [])[0];
                    return rating ? right(rating.rating) : left(ERROR_MESSAGES.UNKNOWN_RATING);
                  });

                  return (
                    <ProtocolContainer key={protocolsGroup[0].fullName}>
                      <ProtocolTitleBox>
                        {logoSrc && (
                          <ProtocolLogoBox>
                            <NextImage
                              src={logoSrc}
                              style={{ objectFit: 'contain', objectPosition: 'center center' }}
                              fill
                              alt=""
                            />
                          </ProtocolLogoBox>
                        )}
                        <ProtocolTitle>{protocolsGroup[0].fullName}</ProtocolTitle>
                      </ProtocolTitleBox>
                      <Networks
                        productSlug={productSlug}
                        versionSlug={versionSlug}
                        networks={networks}
                        networkNames={networkNames}
                        ratings={ratings}
                      />
                    </ProtocolContainer>
                  );
                })}
              <ProposeProtocol href={PROPOSE_PROTOCOL_URL} target="_blank">
                <AddProtocolIcon />
                <ProposeProtocolText>Propose next protocol...</ProposeProtocolText>
              </ProposeProtocol>
            </ProtocolsContainer>
          </Root>
        )
      }
    </Loading>
  );
}

function Networks({
  productSlug,
  versionSlug,
  networks,
  networkNames,
  ratings,
}: {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  networks: NetworkSlug[];
  networkNames: string[];
  ratings: Either<Rating>[];
}) {
  const { redirectTo } = useRouteUtils();

  return (
    <NetworksRoot>
      <Title>Networks:</Title>

      <LinksContainer>
        {networks.map((network, index) => {
          return (
            <ListItem key={network}>
              <Tooltip title={networkNames[index]}>
                <NetworkLink {...redirectTo.protocol({ productSlug, versionSlug, network })}>
                  <NetworkIcon slug={network} fontSize="inherit" />
                  <RatingIcon rating={ratings[index]} />
                </NetworkLink>
              </Tooltip>
            </ListItem>
          );
        })}
      </LinksContainer>
    </NetworksRoot>
  );
}

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '100%',
  maxWidth: 772,
  padding: '24px 16px',
  margin: '0 auto',
  zIndex: 0,

  [theme.breakpoints.up('tabletSM')]: {
    padding: '40px 16px',
  },
}));

const PageTitle = styled('h1')(() => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 400,
  lineHeight: 1,
}));

const ProtocolsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 15,

  [theme.breakpoints.up('mobileMD')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const ProtocolContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: 15,
  padding: '15px 15px 20px',
  color: 'inherit',
  background: theme.palette.background.paper,
  borderRadius: 16,

  [theme.breakpoints.up('tabletLG')]: {
    padding: '20px 20px 30px',
  },
}));

const ProtocolTitleBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
}));

const ProtocolTitle = styled('h2')(() => ({
  margin: 0,
  fontSize: 20,
  fontWeight: 400,
  lineHeight: 1,
  whiteSpace: 'pre-wrap',
}));

const ProtocolLogoBox = styled('div')(() => ({
  position: 'relative',
  flexShrink: 0,
  width: 70,
  height: 70,
}));

const NetworksRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Title = styled('div')(() => ({
  marginRight: 20,
  lineHeight: '24px',
  alignSelf: 'flex-start',
}));

const LinksContainer = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  flexGrow: 1,
  display: 'flex',
  flexWrap: 'wrap',
  gap: 15,
}));

const ListItem = styled('li')({
  position: 'relative',
  listStyleType: 'none',
});

const NetworkLink = styled(NextLink)(() => ({
  textDecoration: 'none',
  fontSize: 24,
  color: colors.black,
}));

const ProposeProtocol = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
  padding: '10px 15px 20px',
  border: `4px dotted ${colors.silver}`,
  borderRadius: 16,
  color: colors.silver,
  transition: theme.transitions.create(['border-color', 'color']),

  '&:hover': {
    borderColor: lighten(colors.charade, 0.05),
    color: lighten(colors.oxfordBlue, 0.1),
  },
}));

const ProposeProtocolText = styled('span')(() => ({
  textAlign: 'center',
  fontSize: 20,
  color: 'inherit',
}));

const AddProtocolIcon = styled(AddIcon)(() => ({
  fontSize: 50,
  color: 'inherit',
}));
